<!-- 交付-运营 -->
<template>
    <!--  -->
    <div @click="sizechange" v-loading="loading" :style="{ width: '100%', height: '100%', overflow: 'hidden' }">
        <div class="title">交付-运营</div>

        <div class="item-row-container">
            <div class="left">
                <div class="item-row" v-for="(item, indedx) in list.slice(0, 11)" :key="item">
                    <div class="item">
                        <div class="item-left">
                            <div class="index" :style="{ backgroundColor: indedx <= 1 ? '#045A55' : '#047770' }">{{
                                indedx
                                + 1 }}</div>
                            <div class="name">{{ item.name }}</div>
                        </div>
                        <div :class="['value', item.simulate === 1 ? 'scheduling' : '']"
                            :style="{ flex: item.warning ? '0' : '0.5em', marginRight: item.warning ? '2em' : '0.5em' }"
                            @click.stop="goUrl(item.url, item.value, item)">
                            {{ item.value }}</div>
                        <div class="item-right" v-if="item.warning">
                            <div class="overdues">
                                <div :class="item.forecast === 0 ? 'forecast1' : 'forecast'"
                                    @click.stop="goUrl(item.forecastUrl, item.forecast)">
                                    预警：{{ item.forecast }}
                                </div>
                                <div :class="item.overdue === 0 ? 'overdue1' : 'overdue'"
                                    @click.stop="goUrl(item.overdueUrl, item.overdue)">
                                    超期：{{ item.overdue }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <el-divider direction="vertical" />
            <div class="right">
                <div class="item-row" v-for="(item, indedx) in list.slice(11, list.length)" :key="item">
                    <div class="item">
                        <div class="item-left">
                            <div class="index" :style="{ backgroundColor: indedx >= 7 ? '#48B82' : '#047770' }">{{
                                (indedx + 11) + 1 }}</div>
                            <div class="name">{{ item.name }}</div>
                        </div>
                        <div class="value" @click.stop="goUrl(item.url, item.value)"
                            :style="{ flex: item.warning ? '0' : '0.5em', marginRight: item.warning ? '1em' : '0.5em' }">
                            {{ item.value }}</div>
                        <div class="item-right" v-if="item.warning">
                            <div class="overdues">
                                <div :class="item.forecast === 0 ? 'forecast1' : 'forecast'"
                                    @click.stop="goUrl(item.forecastUrl, item.forecast)">
                                    预警：{{ item.forecast }}
                                </div>
                                <div :class="item.overdue === 0 ? 'overdue1' : 'overdue'"
                                    @click.stop="goUrl(item.overdueUrl, item.overdue)">
                                    超期：{{ item.overdue }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>





    <span v-show="isShow !== '1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import { delivery_operate_analysis } from "@/api/ChartsDataRequest";

export default {
    name: "customer_status_profile",
    props: ["xref", "type", "chartData"],
    emits: ["closeChart"],
    data() {
        return {
            loading: true,
            list: [
                {
                    name: '需求已承接：',//上岗订单洽谈中(方案未绑定)：
                    value: 0,
                    // url: '/customerManage/stationManagement?jump_type=2',
                    url: '/customerManage/reviewedWorkstations?jump_type=13'
                },
                {
                    name: '未签订上岗单(方案已绑定)：',
                    value: 0,
                    url: '/customerManage/stationManagement?jump_type=32',
                },
                {
                    name: '已签订上岗单(方案未绑定)：',
                    value: 0,
                    url: '/customerManage/stationManagement?jump_type=21',
                },
                {
                    name: '等待排单数：',
                    value: 0,
                    url: '/customerManage/stationManagement?jump_type=3',
                    warning: true,
                    forecast: 0,
                    forecastUrl: '/customerManage/stationManagement?jump_type=22',
                    overdue: 0,
                    overdueUrl: '/customerManage/stationManagement?jump_type=20',
                    // Math.round((模拟工位数量 / 排单总数) * 100)
                    // 当待排单总数小于等于模拟工位数量的50%
                    simulate: 0
                },
                {
                    name: '工单总数：',
                    value: 0,
                    url: '/deliveryManagement/workOrderLIst?jump_type=1',
                    warning: true,
                    forecast: 0,
                    forecastUrl: '/deliveryManagement/workOrderLIst?jump_type=2',
                    overdue: 0,
                    overdueUrl: '/deliveryManagement/workOrderLIst?jump_type=3',
                },
                {
                    name: '排产暂停：',
                    value: 0,
                    url: '/deliveryManagement/workOrderLIst?jump_type=33',
                },
                {
                    name: '工单未启动：',
                    value: 0,
                    url: '/deliveryManagement/workOrderLIst?jump_type=4',
                    warning: true,
                    forecast: 0,
                    forecastUrl: '/deliveryManagement/workOrderLIst?jump_type=5',
                    overdue: 0,
                    overdueUrl: '/deliveryManagement/workOrderLIst?jump_type=6',
                },
                {
                    name: '验证/备料中：',
                    value: 0,
                    url: '/deliveryManagement/workOrderLIst?jump_type=7',
                    warning: true,
                    forecast: 0,
                    forecastUrl: '/deliveryManagement/workOrderLIst?jump_type=8',
                    overdue: 0,
                    overdueUrl: '/deliveryManagement/workOrderLIst?jump_type=9',
                },
                {
                    name: '老化验收中：',
                    value: 0,
                    url: '/deliveryManagement/workOrderLIst?jump_type=10',
                    warning: true,
                    forecast: 0,
                    forecastUrl: '/deliveryManagement/workOrderLIst?jump_type=11',
                    overdue: 0,
                    overdueUrl: '/deliveryManagement/workOrderLIst?jump_type=12',
                },
                {
                    name: '模拟暂停：',
                    value: 0,
                    // url: '/customerManage/stationManagement?work_status=5',
                    url: '/deliveryManagement/workOrderLIst',
                    warning: true,
                    forecast: 0,
                    // forecastUrl: '/customerManage/stationManagement?jump_type=28',
                    forecastUrl: '/deliveryManagement/workOrderLIst',
                    overdue: 0,
                    overdueUrl: '/deliveryManagement/workOrderLIst',
                    // overdueUrl: '/customerManage/stationManagement?jump_type=29',
                },
                {
                    name: '等待出货：',
                    value: 0,
                    url: '/deliveryManagement/workOrderLIst?jump_type=13',
                    warning: true,
                    forecast: 0,
                    forecastUrl: '/deliveryManagement/workOrderLIst?jump_type=15',
                    overdue: 0,
                    overdueUrl: '/deliveryManagement/workOrderLIst?jump_type=14',
                },
                {
                    name: '设备在途：',
                    value: 0,
                    url: '/deliveryManagement/workOrderLIst?jump_type=16',
                },
                {
                    name: '等待现场部署：',
                    value: 0,
                    // url: '/customerManage/stationManagement?work_status=7',
                    url: '/deliveryManagement/workOrderLIst',
                    warning: true,
                    forecast: 0,
                    // forecastUrl: '/customerManage/stationManagement?jump_type=24',
                    forecastUrl: '/deliveryManagement/workOrderLIst',
                    overdue: 0,
                    // overdueUrl: '/customerManage/stationManagement?jump_type=25',
                    overdueUrl: '/deliveryManagement/workOrderLIst',
                },
                {
                    name: '现场部署中：',
                    value: 0,
                    // url: '/customerManage/stationManagement?work_status=8',
                    url: '/deliveryManagement/workOrderLIst',
                    warning: true,
                    forecast: 0,
                    // forecastUrl: '/customerManage/stationManagement?jump_type=18',
                    forecastUrl: '/deliveryManagement/workOrderLIst',
                    overdue: 0,
                    overdueUrl: '/deliveryManagement/workOrderLIst',
                    // overdueUrl: '/customerManage/stationManagement?jump_type=7',
                },
                {
                    name: '现场部署暂停：',
                    value: 0,
                    // url: '/customerManage/stationManagement?work_status=9',
                    url: '/deliveryManagement/workOrderLIst',
                    warning: true,
                    forecast: 0,
                    // forecastUrl: '/customerManage/stationManagement?jump_type=30',
                    forecastUrl: '/deliveryManagement/workOrderLIst',
                    overdue: 0,
                    overdueUrl: '/deliveryManagement/workOrderLIst',
                    // overdueUrl: '/customerManage/stationManagement?jump_type=31',
                },
                {
                    name: '交付-非稳定运行：',
                    value: 0,
                    // url: '/customerManage/stationManagement?work_status=19',
                    url: '/deliveryManagement/workOrderLIst',

                },
                {
                    name: '本月已验收交付：',
                    value: 0,
                    // url: '/customerManage/stationManagement?jump_type=26',
                    url: '/customerManage/stationManagement',
                },
                {
                    name: '运维异常运行：',
                    value: 0,
                    url: '/customerManage/stationManagement?work_status=12',
                    // 非稳定运行收费
                },
                {
                    name: '本月退机数',
                    value: 0,
                    url: '/customerManage/stationManagement?jump_type=27',
                },
                {
                    name: '当前部署机器人总数',
                    url: '/customerManage/stationManagement?work_status=11,12,13,14',
                    value: 0,
                },
                {
                    name: '运维异常暂停',
                    url: '/customerManage/stationManagement?work_status=14',
                    value: 0,
                }
            ],
            option: {},

            isShow: ''
        };
    },
    methods: {
        // 图表关闭
        handleCloseChart() {
            this.$emit("closeChart");
        },
        createChart() {
          // 已审核需求列表
          const reviewedWorkstations = '/customerManage/reviewedWorkstations?ids='
          // 工位列表
          const stationManagement = '/customerManage/stationManagement?ids='
            this.loading = false;
            delivery_operate_analysis().then((res) => {

                if (res.data.code == 200) {
                    this.list.forEach(item => {
                        if (item.name === '需求已承接：') {//上岗订单洽谈中(方案未绑定)：
                            item.value = res.data.data.demand_assign
                        } else if (item.name === '已签订上岗单(方案未绑定)：') {
                            item.value = res.data.data.permit_no_delivery.length
                            item.url = stationManagement + res.data.data.permit_no_delivery.join(',')
                        } else if (item.name === '未签订上岗单(方案已绑定)：') {
                            item.value = res.data.data.bind_scheme_no_permit.length
                            item.url = reviewedWorkstations + res.data.data.bind_scheme_no_permit.join(',')
                        } else if (item.name === '等待排单数：') {
                            item.value = res.data.data.permit_cont.length
                            item.forecast = res.data.data.permit_cont_warn.length
                            item.overdue = res.data.data.permit_cont_delay.length
                            // 模拟工位总数量颜色预警
                            item.simulate = res.data.data.analog_work_station_warn ? 1 : 0
                            item.url = stationManagement + res.data.data.permit_cont.join(',')
                            item.forecastUrl = stationManagement + res.data.data.permit_cont_warn.join(',')
                            item.overdueUrl = stationManagement + res.data.data.permit_cont_delay.join(',')
                        } else if (item.name === '工单总数：') {
                            item.value = res.data.data.work_order_total
                            item.forecast = res.data.data.work_order_warn
                            item.overdue = res.data.data.work_order_delay
                        } else if (item.name === '排产暂停：') {
                            item.value = res.data.data.work_order_plan_stop_total
                        } else if (item.name === '工单未启动：') {
                            item.value = res.data.data.work_order_not_start_total
                            item.forecast = res.data.data.work_order_not_start_warn
                            item.overdue = res.data.data.work_order_not_start_delay
                        } else if (item.name === '验证/备料中：') {
                            item.value = res.data.data.check_material_total
                            item.forecast = res.data.data.check_material_warn
                            item.overdue = res.data.data.check_material_delay
                        } else if (item.name === '老化验收中：') {
                            item.value = res.data.data.check_old_total
                            item.forecast = res.data.data.check_old_warn
                            item.overdue = res.data.data.check_old_delay
                        } else if (item.name === '模拟暂停：') {//
                            item.value = res.data.data.simulate_stop
                            item.url = item.url + '?ids=' + res.data.data.simulate_stop_ids

                            item.forecast = res.data.data.simulate_stop_warn || 0
                            item.forecastUrl = item.forecastUrl + '?ids=' + res.data.data.simulate_stop_warn_ids

                            item.overdue = res.data.data.simulate_stop_delay || 0
                            item.overdueUrl = item.overdueUrl + '?ids=' + res.data.data.simulate_stop_delay_ids

                        } else if (item.name === '等待出货：') {
                            item.value = res.data.data.wait_send
                            item.forecast = res.data.data.wait_send_warn || 0
                            item.overdue = res.data.data.wait_send_delay || 0
                        } else if (item.name === '设备在途：') {
                            item.value = res.data.data.device_on_way
                        } else if (item.name === '等待现场部署：') {//
                            item.value = res.data.data.wait_deploy
                            item.url = item.url + '?ids=' + res.data.data.wait_deploy_ids

                            item.forecast = res.data.data.wait_deploy_warn || 0
                            item.forecastUrl = item.forecastUrl + '?ids=' + res.data.data.wait_deploy_warn_ids

                            item.overdue = res.data.data.wait_deploy_delay || 0
                            item.overdueUrl = item.overdueUrl + '?ids=' + res.data.data.wait_deploy_delay_ids
                        } else if (item.name === '现场部署中：') {//
                            item.value = res.data.data.deploying
                            item.url = item.url + '?ids=' + res.data.data.deploying_ids

                            item.forecast = res.data.data.deploying_warn || 0
                            item.forecastUrl = item.forecastUrl + '?ids=' + res.data.data.deploying_warn_ids

                            item.overdue = res.data.data.deploying_delay || 0
                            item.overdueUrl = item.overdueUrl + '?ids=' + res.data.data.deploying_delay_ids
                        } else if (item.name === '现场部署暂停：') {//
                            item.value = res.data.data.deploy_stop
                            item.url = item.url + '?ids=' + res.data.data.deploy_stop_ids

                            item.forecast = res.data.data.deploy_stop_warn || 0
                            item.forecastUrl = item.forecastUrl + '?ids=' + res.data.data.deploy_stop_warn_ids

                            item.overdue = res.data.data.deploy_stop_delay || 0
                            item.overdueUrl = item.overdueUrl + '?ids=' + res.data.data.deploy_stop_delay_ids
                        } else if (item.name === '交付-非稳定运行：') {//
                            item.value = res.data.data.bad_running
                            item.url = item.url + '?ids=' + res.data.data.bad_running_ids
                        } else if (item.name === '本月已验收交付：') {//
                            item.value = res.data.data.charge_total
                            item.url = item.url + '?ids=' + res.data.data.charge_total_ids
                        } else if (item.name === '运维异常运行：') {
                            item.value = res.data.data.bad_running_charge
                        } else if (item.name === '本月退机数') {
                            item.value = res.data.data.back_total
                        } else if (item.name === '当前部署机器人总数') {
                            item.value = res.data.data.robot_total
                        } else if (item.name === '运维异常暂停') {
                            item.value = res.data.data.question_stop
                        }
                    })
                }
                this.loading = false;
            })
        },

        sizechange() {
            this.MyChart.resize();
        },
        // 跳转
        goUrl(row, value, item) {
            if (parseInt(value) !== 0) window.$wujie.props.method.propsMethod(row)
        }
    },
    mounted() {
        this.createChart();
        this.isShow = sessionStorage.getItem("isShow")
    },
};
</script>

<style scoped lang="scss">
.vue-chart-setting {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 2px;
    right: 24px;
    background: url("../../assets/settings.svg");
    background-size: 16px 16px;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-origin: content-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

.title {
    height: 68px;
    line-height: 68px;
    font-size: 20px;
    text-align: center;
    /* background-color: rgba(255, 255, 255, 0.08); */
    color: #fff;
}

.item-row-container {
    display: flex;

    .left {
        flex: 1;
    }

    :deep(.el-divider) {
        height: 540px; //490
        margin-top: 10px;
        margin: 10px 0 0 0;
        border-color: #005F59;
    }

    .right {
        flex: 1;
    }
}


.item-row {
    padding: 0 14px;
}

.item {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #005F59;
    border-radius: 8px;
    color: #fff;
    margin-top: 10px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;

    .item-left {
        display: flex;
        flex: 1;
        align-items: center;

        .index {
            width: 15px;
            padding: 0 2px;
            background-color: #089187;
            border-radius: 8px 0px 0px 8px;
        }

        .name {
            width: max-content;
            margin-left: 5px;
        }
    }

    .value {
        // flex: 1;
        font-size: 20px;
        color: #F9B707;
        // background-color: #005F59;
        text-align: right;
        cursor: pointer;
    }

    .scheduling {
        background-color: #FF0000;
        border-radius: 50%;
        aspect-ratio: 1/1;
        /* Maintain a 1:1 aspect ratio */
        margin-right: 1.7em !important;
        height: 30px;
        line-height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .item-right {
        flex: 1;
        display: flex;
        justify-content: flex-end;

        .overdues {
            display: flex;
            height: 30px;
            line-height: 30px;
            margin-right: 5px;


            .forecast {
                width: 70px;
                // padding: 0px 10px;
                background-color: #FE7E24;
                margin-right: 8px;
                border-radius: 30px;
                cursor: pointer;
            }

            .forecast1 {
                width: 70px;
                color: #fff;
                border: 1px solid #ccc;
                // padding: 0px 13px;
                margin-right: 8px;
                border-radius: 30px;
                cursor: pointer;
            }

            .overdue {
                width: 70px;
                // padding: 0px 10px;
                border-radius: 30px;
                background-color: #FF0000;
                cursor: pointer;
            }

            .overdue1 {
                width: 70px;
                color: #fff;
                border: 1px solid #ccc;
                // padding: 0px 13px;
                border-radius: 30px;
                cursor: pointer;
            }
        }
    }
}
</style>
