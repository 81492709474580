<template>
	<el-dialog title="选择图表" v-model="myVisible" @close="OnClose()" v-loading="loading" width="960px">
		<div class="select-header">
			<div class="asset-detail-tabs">
					<div>
						<el-tabs v-model="state" class="demo-tabs" @tab-click="handleClick">
              <el-tab-pane label="看板图表" name="first"></el-tab-pane>
              <el-tab-pane label="小看板数据" name="second"></el-tab-pane>
            </el-tabs>
					</div>
					<div v-show="!isShow">
						<el-select v-model="value" placeholder="请选择数据类型" class="select-type" clearable>
							<el-option v-for="item in selCategoryOptions" :key="item.label_value" :label="item.dict_label"
							:value="item.label_value">
							</el-option>
						</el-select>
						<el-button type="info" class="reset-button" round @click="resetChartSelected">重置</el-button>
					</div>
			</div>
		</div>

		<el-row :gutter="12" v-loading="loading" class="el-row-control" v-if="!isShow">
			<el-col :span="6" v-for="item in chartsData" :key="item.id">
				<el-card class="chart-boxes">
					<div style="text-align: left">
						<el-checkbox :label="item.id" :value="item.id" v-model="selectCharts"><BR /></el-checkbox>
					</div>
					<img v-if="item.photo_list.length > 0" :src="`https://${item.photo_list[0].file_url}`" class="chart-image" />
					<div>
						<div class="chart-desc">
							<!-- <el-popover placement="top-start" title="图表名称" :width="200" trigger="hover" :content="item.name"> -->
								<!-- <template #reference> -->
									<div class="text-ellipsis">图表名称：{{ item.name }}
									</div>
								<!-- </template> -->
							<!-- </el-popover> -->
							<div class="text-ellipsis">数据类型：{{ item.type_text }}</div>
						</div>
					</div>
				</el-card>
			</el-col>
		</el-row>

		<!-- 小看板 -->
		<!-- v-else -->
		<!-- <div class="flex father-box" v-show="isShow">
		<keep-alive :include="'TestChart'" >
			<TestChart :bulletin="bulletin" @checked="checkedBoardId" ></TestChart>
		</keep-alive>
	</div> -->

		<div class="flex father-box" v-else>
			<TestChart :bulletin="bulletin" @checked="checkedBoardId"  @cache="cacheData" ></TestChart>
		</div>
	

		<div class="action-box" >
			<el-button round class="footer-reset-btn" @click="OnClose()">取消</el-button>
			<el-button type="primary" @click="saveSelectedChart" round style="background-color: rgb(0, 95, 89)"
				:loading="btnLoading">确定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import {
	apiGetSelectableCharts,
	apiSaveSelectedCharts,
	apiGetChartsType,
	apichartDoard,
	investorChart
} from "@/api/ChartsSettingRequest";
import TestChart from "./Waterfall.vue";

export default {
	components: {
		TestChart
	},
	name: "SelectChartPan",
	inject: ["reload"],
	

	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		showSelectPannel: {
			type: Function,
		},
		groupChartID: String,
		invesValue:Storage,
		selecte:String
	},
	data() {
		return {
			myVisible: this.visible,
			selCategoryOptions: [],
			chartsData: [],
			selectCharts: [],
			loading: true,
			value: "",
			btnLoading: false,
			state:'first',//默认选中
			isShow:false,
			bulletin:[],//看板数据
			ids:'',

			checkedBoardIds:{ },
			states:{
				checkAll:{ },
				isIndeterminate: { },
			},
			a:{ },
			b:{ }
		};
	},

	methods: {
		sendMessage() {
			window.parent.postMessage(
				{
					dialogVisible: false,
					groupChartID: this.groupChartID,
					type: "chartDialog",
				},
				"*"
			);
		},
		OnClose() {
			this.$emit("showSelectPannel", false);
			this.sendMessage();
			sessionStorage.removeItem('checkedBoardId')
			sessionStorage.removeItem('checkAll')
			sessionStorage.removeItem('isIndeterminate')
		},
		// 小看板选中的图表
		checkedBoardId(v){
			const values = Object.values(v).flat()// 提取所有值并拼接成一个数组
			this.ids = values.join(',');
		},

		// 保存选中的图表
		saveSelectedChart() {
			// if (this.groupChartID && this.selectCharts.length > 0) {
				this.btnLoading = true
				apiSaveSelectedCharts({
					group_id: this.groupChartID,//分组id
					board_id: this.selectCharts.join(","),//看板id
					is_investor:this.invesValue,//是否经营看板1:是0:否默认否
					small_board_id:this.ids//小看板id
				}).then((res) => {
					if (res.data.code == 200) {
						this.$message({
							message: "保存成功",
							type: "success",
							duration: 1000,
						});
						this.$emit('refresh',this.groupChartID)
						this.btnLoading = false						
						sessionStorage.removeItem('checkedBoardId')
						sessionStorage.removeItem('checkAll')
						sessionStorage.removeItem('isIndeterminate')
					} else {
						this.$message({
							message: res.data.message,
							type: "warning",
							duration: 1000,
						});
					}
					window.parent.postMessage(
						{
							dialogVisible: false,
							type: "chartDialog",
						},
						"*"
					);
					
					this.myVisible = false;
					this.reload({ id: this.groupChartID });
					
				}).catch((err) => {
					this.btnLoading = false
				})
			// } else {
			// 	this.$message({
			// 		message: "请选择图表条目",
			// 		type: "warning",
			// 		duration: 1000,
			// 	});
			// }
		},


		resetChartSelected() {
			this.selectCharts = [];
			this.value = "";
		},
		// 小看板数据
		async initdata(val){
			const res = await apichartDoard({group_id:val})
			if(res.data.code==200){
				this.bulletin = res.data.data
			}
		},

		// Tabs切换
		handleClick(v){
			if(v.props.label =='小看板数据'){
				this.isShow=true
			}else{
				this.isShow=false
			}
		},
		cacheData(v,row){
			this.checkedBoardIds = v
			this.states.checkAll = row
			console.log(this.checkedBoardIds,this.states.checkAll);
		},
	},

	mounted() {
		if(this.invesValue=='1'){
			// investorChart(this.groupChartID).then((res)=>{ //获取投资人可选择的图表(控制有些图片看不到)
			// 	if(res.data.code==200){
			// 		this.chartsData = res.data.data ;
			// 		this.loading = false;
			// 	}
			// })
			apiGetSelectableCharts({ gid: this.groupChartID }).then((res) => {//获取投资人可选择的图表
				if (res.data.code == 200) {
					this.chartsData = res.data.data || res.data.list;
					this.loading = false;
				}
			})
		}else{
			apiGetSelectableCharts({ gid: this.groupChartID }).then((res) => {//首页和其他可选择的图表
				if (res.data.code == 200) {
					this.chartsData = res.data.data || res.data.list;
					this.loading = false;
				}
			})
		}

		
		apiGetChartsType().then((res) => {
			this.selCategoryOptions = res?.data?.data?.list;
			
		});
	},
	watch: {
		visible: {
			immediate: true,
			handler(newVal) {
				this.myVisible = newVal;
				if(newVal){
					this.initdata(this.selecte) //调用小看板
				}
			},
		},
		value: {
			handler(e) {
				apiGetSelectableCharts({ gid: this.groupChartID, type: e }).then(
					(res) => {
						if (res.data.code == 200) {
							this.chartsData = res.data.data || res.data.list;
							this.loading = false;
						}
					}
				);
			},
		},
	},
};
</script>

<style >
.el-dialog {
	border-radius: 8px !important;
}

.chart-pannel-title {
	float: left;
	font-size: 16px;
	margin-top: 10px;
	margin-left: 8px;
	color: rgb(0, 95, 89);
}

.el-dialog__title {
	color: rgb(0, 95, 89) !important;
}

.el-row-control {
	max-height: 430px;
	min-height: 430px;
	overflow-y: scroll;
}

.footer-reset-btn {
	border: 1px solid rgb(0, 95, 89) !important;
	color: rgb(0, 95, 89) !important;
}

.chart-boxes {
	margin-bottom: 16px;
	overflow: hidden;
}

.chart-image {
	height: 100px;
	width: 178px;
	object-fit: contain !important;
}

.select-type {
	margin-right: 16px;
	width: 160px;
}

.el-card__body {
	overflow: hidden;
	padding-top: 0px !important;
}

.reset-button {
	border: 1px rgb(249, 183, 7) solid !important;
	background-color: #f5f5f5 !important;
	color: rgb(0, 95, 89) !important;
}

.el-dialog__header {
	text-align: left;
}

.el-dialog__body {
	padding-top: 0px !important;
}

.select-header {
	border-bottom: 1px solid rgb(45, 126, 120);
	margin-bottom: 8px;
	padding: 4px;
	text-align: right;
	/* background-color: rgba(0, 66, 62, 0.1); */
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.el-overlay-dialog {
	position: fixed;
	overflow: hidden !important;
}

.action-box {
	text-align: right;
	padding-top: 8px;
	border-top: 2px solid #f5f5f5;
}

.chart-desc {
	text-align: left;
	color: rgb(0, 95, 89);
  overflow-y: scroll;
  height: 40px;
}

.text-ellipsis {
	/* overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis; */
}

.el-col-6 {
	height: 200px !important;
}

.select-header .el-select {
	border-radius: 30px;
	border: 1px solid rgb(0, 95, 89);
}

.select-header .el-select .el-input__wrapper {
	box-shadow: 0 0 0 0 transparent inset !important;
	background: transparent;
}

.select-header .el-select .el-input.is-focus .el-input__wrapper {
	box-shadow: 0 0 0 1px transparent inset !important;
}

.select-header .el-select .el-input__wrapper.is-focus {
	box-shadow: 0 0 0 1px transparent inset !important;
}

.el-input--suffix {
	border: 0;
}

::-webkit-scrollbar {
	width: 4px;
	background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
	background: rgb(104, 161, 158);
}
.el-dropdown__popper {
    --el-dropdown-menuItem-hover-fill: #FFC824 !important;;
    --el-dropdown-menuItem-hover-color: #fff !important;;
    --el-dropdown-menu-index: 10;
}
.asset-detail-tabs{
	display: flex;
	justify-content: space-between;
	align-content: center;
}
/* Tabs */
/* .demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
} */
.el-tabs__nav{
	height: 35px;
	border-radius: 5px;
	border: 1px solid #FFC824;
}
.el-tabs__active-bar {
	height: 0;
	border-bottom: none;
}
.el-tabs__item{
	padding:0 15px !important;
}
/* 悬停 */
.el-tabs__item:hover{
	color: #000 !important;
}
/* 选中 */
.el-tabs__item.is-active{
	background-color: #FFC824;
	color: #000 !important;
	padding: 0 15px !important;
}

/* 去掉tabs标签栏下的下划线 */
.el-tabs__nav-wrap::after {
	background-color:transparent !important;
}
/* 去掉tabs切换标签下的蓝色下划线： */
.el-tabs__active-bar{
	background-color:transparent !important;
}



.father-box {
  height: 420px; 
  overflow-y: scroll;
}


</style>